import { ChainId } from 'constants/constants';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useState,
} from 'react';
import { useAccount, useSwitchChain } from 'wagmi';

type SelectedChain = {
  chainId: number;
  setChainId: (chainId: number) => void;
};

const initialValue = {
  chainId: 1,
  setChainId: () => {},
};

export const ChainContext = createContext<SelectedChain>(initialValue);

const ChainProvider: FC<PropsWithChildren> = ({ children }) => {
  const { chain, isConnected } = useAccount();

  const [chainId, setChainId] = useState<number>(
    chain?.id || initialValue.chainId
  );
  const { chains, switchChain } = useSwitchChain()
  const {address: userAccount} = useAccount();

  const APY_ENDPOINT =
    process.env.APY_ENDPOINT || process.env.GATSBY_APY_ENDPOINT;
  const APY_API_KEY =
    process.env.APY_API_KEY || process.env.GATSBY_APY_API_KEY;

  if (!APY_ENDPOINT) {
    throw new Error('To continue set the APY_ENDPOINT environment variable');
  }
  if (!APY_API_KEY) {
    throw new Error(
      'To continue set the APY_API_KEY environment variable'
    );
  }

  React.useEffect(() => {
    if (chain?.id) {
      setChainId(chain.id);
      if (userAccount){
        if (chain?.id === ChainId.Mainnet || chain?.id === ChainId.Polygon) {
          const deployerId = 'ichi';
          fetch(APY_ENDPOINT, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'api-key': APY_API_KEY,
            },
            body: JSON.stringify({
              "query": `{ totalPositions(userIdentifier: "${userAccount}" blueprintId: "${deployerId}"){requestId count} }`,
            })
          })
        };
      }
    }
  }, [chain?.id, setChainId]);

  const setNewChainId = (newChainId: number) => {
    try {
      if (chain?.id || isConnected) {
        void switchChain({
          chainId: newChainId,
        });
      } else {
        setChainId(newChainId);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <ChainContext.Provider
      value={{
        chainId,
        setChainId: setNewChainId,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export default ChainProvider;
