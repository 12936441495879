import { Colors } from 'common/models/theme';

const darkColors: Colors = {
  transparent: 'transparent',
  primaryTextColor: '#ffffff',
  warningTextColor: '#de7921',
  spinnerColor: '#ffffff',
  black: '#000000',
  white: '#ffffff',
  // headingColor: '#00387C',
  headingColor: '#0676FF',
  selectedColor: '#1877F6',
  sidebarTextColor: '#ffffff',
  sidebarTextSelectedColor: '#ffffff',
  sidebarItemSelectedColor: '#0676FF',
  textColor: '#43414e',
  subTextColor: '#5381B9',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  // primary: '#2aa275',
  primary: 'rgb(0, 189, 123)',
  primaryDisabled: '#CCF2E5',
  primaryHover: '#2aa275',
  secondary: '#edcd37',
  secondaryHover: '#edcd37',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',
  // Wallet
  walletButtonBgColor: '#0C3E52',
  // General
  primaryButtonBgColor: '#1DBC7D',
  primaryButtonDisabledBgColor: '#999999',
  primaryButtonColor: '#ffffff',
  secondaryButtonBgColor: '#e8f8f3',
  footerBgColor: '#122F3D',
  hrBgColor: '#707070',
  // borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
  sidebarBackgroundColor: '#0C0F18',
  contentBgColor: '#131823',
  contentInnerBgColor: '#0F284C',
  contentInnerInnerBgColor: '#122139',
  annoncementBgColor: '#0F284C',
  hamburgerIcon: '#ffffff',
  // Tabs
  activeTabColor: '#ffffff',
  inactiveTabColor: '#5381B9',
  backgroundColor: '#131823',
  borderColor: '#252A34',
  btnViewAllColor: '#0676ff',
  // Treasury
  treasuryPositionBorder: 'none',
  treasuryPositionBgColor: '#122139',
  treasuryPositionNameColor: '#5280B7',
  treasuryTableSubTextTwoColor: '#00BD7B',
  // Farm & Vault
  farmDashesColor: '#0069EA',
  noRewardsButtonColor: '#0C0F18',
  vaultBannerBorderColor: '#252A34',
  // General Table
  tableBorderBottom: '1.1px solid #252A34',
  // General Input
  inputBorder: '1px solid #252A34',
  inputBorderColor: '#252A34',
  inputLabelColor: '#5280B7',
  inputBgColor: '#0C0F18',
  inputPlaceholderColor: '#5280B7',
  // Ichi Balance
  ichiBalanceButtonBgColor: '#1877F6',
  ichiBalanceBgColor: '#122139',
  ichiBalanceHeaderBorderBottom: '1px solid #243757',
  ichiBalanceHeaderTextColor: '#ffffff',
  ichiBalancePrimaryTextColor: '#ffffff',
  // Modal
  modalBgColor: '#152037',
  // redeem
  innerBoxBgColor: '#152037',
  // select stablecoin
  selectStablecoinBgColor: '#EDF5FF',
  // Wallet
  walletPopperHeaderBorderBottom: '1px solid #243757',
  walletPopperHeaderTextColor: '#ffffff',
  walletPopperBgColor: '#243757',
  walletPopperTextColor: '#243757',
};

export default darkColors;
